
import Vue from 'vue';

export default Vue.extend({
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      activeIndex: "1",
      content: "浙江五疆科技发展有限公司由世界级龙头企业新凤鸣集团股份有限公司孵化成立，于2019年，总部设于乌镇大数据产业园，注册资金1000万元，是省、市工业互联网推荐服务商，以及全国首批13家工业互联网标识解析体系应用供应商之一。目前，服务数百家企事业单位并技术支撑桐乡和嘉兴市人民政府获得2项数字化改革揭榜挂帅项目。其中，“一键”报表已上线超3500家企业并被列为全省推广。2021年先后获评桐乡市“服务业优秀企业”、“数字经济发展优秀企业”和浙江省“青工创新创效金奖”，示范效应效益凸显。",
      title: "公司简介",
      conHeight:{
        height:"800px"
      }
    }
  },
  created(){
    window.addEventListener('resize', this.changeSize);
    this.changeSize();
  },
  methods: {
    handleSelect(e: any) {
      const obj: any = {
        1: "公司简介",
        2: "公司理念",
        3: "公司资质",
        4: "公司产品",
      }
      const content: any = {
        1: "浙江五疆科技发展有限公司由世界级龙头企业新凤鸣集团股份有限公司孵化成立，于2019年，总部设于乌镇大数据产业园，注册资金1000万元，是省、市工业互联网推荐服务商，以及全国首批13家工业互联网标识解析体系应用供应商之一。目前，服务数百家企事业单位并技术支撑桐乡和嘉兴市人民政府获得2项数字化改革揭榜挂帅项目。其中，“一键”报表已上线超3500家企业并被列为全省推广。2021年先后获评桐乡市“服务业优秀企业”、“数字经济发展优秀企业”和浙江省“青工创新创效金奖”，示范效应效益凸显。",
        2: "五疆发展秉承世界互联网大会创新精神，践行携手共建人类网络命运共同体理念，致力于打造企业网络化“第五疆域”，赋能企业绿色化智能化转型升级。",
        3: "具有IS014001、ISO 27001、ISO 20000、ISO 9001和CMMI3、CCRC信息系统安全集成认证3级认证等“4+2”资质和认证。同时是全国首批13家工业互联网标识解析体系应用供应商之一，建设运营工业互联网标识解析体系浙北中心节点，服务27家，标识注册解析超2亿个。",
        4: "具有“1(凤平台)+12（核心软件产品）+25（行业解决方案）”整套工业互联网解决方案和建设咨询、设计研发和交付运维服务，已经服务国家电网、久立集团、荣晟环保、京马电机和经信、公安、统计、发改等企业侧、政府侧等数百家单位，获得浙江省数字经济揭榜挂帅项目2个。其中，一键报表项目已经上线运行3500家，并纳入全省推广项目。",
      }
      this.title = obj[e];
      this.content = content[e];
    },
    changeSize(){
      this.conHeight.height = window.innerHeight-100+'px';
      // (this.main as any).style.height = window.innerHeight - 320 + 'px';
    }
  }
});
